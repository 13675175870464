import styled from 'styled-components';
import { Button } from 'reactstrap';

export const Navbar = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0096e8ff;
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 3rem;
  
`;

export const Title = styled.h2`
  font-family: 'SF Pro Display';
  color: #0d2355fd;
`;

export const Subtitle = styled.h5`
  font-family: 'SF Pro Display';
  color: #0d2355fd;
`;
export const LabelText = styled.span`
  font-family: 'SF Pro Display';
  font-size: 14px;
  color: #0d2355fd;
`;

export const DownloadButton = styled(Button)`
  width: 90vmin;
  margin: 0 1rem;
`;