import React, {useState} from 'react';
import {  Input } from 'reactstrap';


const PhoneInput = ({name, id, placeholder, onChange}) => {

  const [value, setValue] = useState('');

  function mascaraDeTelefone (numeroTelefone) {
    const textoAtual = numeroTelefone;
   
    let textoAjustado;

    textoAjustado = textoAtual
      .replace(/[^\d]/g, '')
      .replace(/(\d{2})(\d{1})/, '($1) $2')
      .replace(/\((\d{2})\) (\d{1})(\d{4})/, '($1) $2 $3')
      .replace(/\((\d{2})\) (\d{1}) (\d{4})(\d{4})/, '($1) $2 $3-$4');

    setValue(textoAjustado || textoAtual);
    onChange(textoAjustado || textoAtual);
  };
  return <div className="phone-input-container">
    <h6>+55</h6>
    <Input value={value} type="text" name={name} id={id} placeholder={placeholder} className="phone-input" maxLength={16} onChange={(event) => mascaraDeTelefone(event.target.value)}/>
  </div>
}

export default PhoneInput;