import React, {useState, useEffect} from 'react';
import {
  useParams,
  useHistory
} from "react-router-dom";
import { useAlert } from 'react-alert'
import axios from 'axios';
import { Button, Form, FormGroup, Spinner } from 'reactstrap';
import DefaultInput from '../DefaultInput';
import PhoneInput from '../PhoneInput';
import About from '../About';

import {ERROR_CODES} from '../../Constants';

const FormPage = () => {
  const {referal_code} = useParams();
  const [isValid, setIsValid] = useState(false);
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const alert = useAlert()
  const history = useHistory();


  function onPhoneChange(value) {
    console.log('current value: ', value);
    console.log('current value: ', value.length);

    const phoneValid = value.length === 16;

    setIsValid(phoneValid);
    setPhone(value);
  }

  async function submit(event) {
    event.preventDefault();
    const rowPhoneNumber =  phone.replace(/[^\d]/g, '');
    const phoneNumber = `+55${rowPhoneNumber}`;
    setLoading(true);
    try {
      const result = await axios.request({
        baseURL: process.env.REACT_APP_BASE_COUPON_URL,
        url: 'customers/indications',
        method: 'POST',
        data: {
          new_friend_phone: phoneNumber,
          referal_code
        }
      });  
      
      console.log('sucesso: ', result);
      setLoading(false);
      history.push('/download');
    } catch (error) {
      setLoading(false);
      console.log('Error, ', error.response);
      showErrorMessage(error.response.data);
    }
  }

  function showErrorMessage(errorMessage) {
    let message = 'Ops! Aconteceu um erro ineperado, tente mais tarde.';
    const complement = 'Mas não fique chatedado, nós enviamos diversos descontos especiais para passageiros.'
    switch(errorMessage) {
      case ERROR_CODES.INVALID_REFERAL_CODE:
        message = 'Ops! Não encontramos o código de convite informado em nossa base.';
        break;
      case ERROR_CODES.INVALID_PHONE_NUMBER:
        message = 'Ops! Precisamos que informe o número corretamente.';
        break;
      case ERROR_CODES.CUSTOMER_ALREADY_EXISTS:
        message = 'Ops! Parace que o seu número já está cadastrado em nosso sistema. Este desconto é válido apenas para novos usuários.';
        break;
      case ERROR_CODES.REFERAL_CODE_NOT_FOUND:
        message = 'Ops! Parece que não encontramos esse convite em nosso sistema.';
        break;
      default:
        message = 'Ops! Aconteceu um erro ineperado, tente mais tarde.';
    }

    alert.show(`${message} ${complement}`)
  }

  return <div className="App">
    <header className="App-header">
      <div className="App-logo" alt="logo" >
        <h5 className="banner-title">Você ganhou <strong className="">R$ 5</strong> para <br/>
        usar na sua primeira <br/>
        viagem
        </h5>
      </div>
    </header>

    <Form className="form-phone">
      <FormGroup>
        <PhoneInput type="text" name="phone" id="phone" placeholder="Inserir número de celular" onChange={onPhoneChange} />
      </FormGroup>
      <FormGroup>
        <DefaultInput value={referal_code} type="text" name="referal_code" id="referal_code" placeholder="AYOCUPOM10" />
      </FormGroup>
      <Button className="submit-button" disabled={!isValid} onClick={submit}>
        {!loading ? "Quero meu desconto" :
        <Spinner animation="border" variant="info" />}
      </Button>
    </Form>

    <About />
</div>
}

export default FormPage;