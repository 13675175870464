import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import FormPage from './components/FormPage';
import DownloadPage from './components/DownloadPage';

import './App.css';

function App() {
  return (
    <Router>
        <Route path="/invite/:referal_code">
          <FormPage />
        </Route>
        <Route path="/download">
          <DownloadPage />
        </Route>
    </Router>
  );
}

export default App;
