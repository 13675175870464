import React from 'react';

import Logo from '../../assets/ICON-AYO.png'
import TermosButton from '../TermosButton'

import {ContentContainer,MainContainer,Navbar, Title, Subtitle, DownloadButton, LabelText} from './styles';

const DownloadPage = () => {
  return <div className="App">
    <header className="App-header">
      <Navbar className="navbar navbar-light navbar-logo">
          <img src={Logo} width="70" height="70" className="d-inline-block align-top" alt="" />
      </Navbar>
    </header>

    <MainContainer>
      <ContentContainer>
        <Title>Seu desconto já está te<br/> esperando. </Title>
        <Subtitle>Agora é só baixar o app da ayo e <br/> aproveitar a sua primeira viagem</Subtitle>
        <div className="car-banner"></div>
        <DownloadButton className="submit-button" onClick={(e) => {
          e.preventDefault();
          window.open('https://bit.ly/ayo_indique', '_blank');
        } }>Baixar agora</DownloadButton>
        <LabelText>Ou procure por "ayo Passageiro" em sua loja de aplicativo</LabelText>
      </ContentContainer>

      <TermosButton />
    </MainContainer>



</div>
}

export default DownloadPage;