import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import TermosButton from '../TermosButton';
import Icon1 from '../../assets/ICON-SOBRE1.svg';
import Icon2 from '../../assets/ICON-SOBRE2.svg';
import Icon3 from '../../assets/ICON-SOBRE3.svg';

const About = () => {
  return <div className="about-container">
      <div className="about-header">
        <div className="about-header--left"></div>
        <div className="about-header--title">Sobre a ayo</div>
        <div className="about-header--right"></div>
      </div>
     
      <ListGroup className="about-list">
        <ListGroupItem className="about-list-item">
        <img src={Icon1} alt="Icone ayo, viagem com profissionais" width="20" height="25" />
          Segurança, viaje com profissionais.
          </ListGroupItem>
        <ListGroupItem className="about-list-item">
        <img src={Icon2} alt="Icone ayo, Pague pelo próprio app" width="20" height="25" />
          Pague pelo próprio app.
        </ListGroupItem>
        <ListGroupItem className="about-list-item">
        <img src={Icon3} alt="Icone ayo, Economize, vá de ayo" width="20" height="25" />
          Economize, vá de ayo
        </ListGroupItem>
    </ListGroup>

    <TermosButton />
  </div>
}


export default About;